.project__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.project__container h3 {
    margin-top: 0.5rem;
}

.project__container a {
    margin-top: 0.5rem;
}

.project__item {
background: var(--color-bg-variant);
padding: 1.2rem;
border-radius: 2rem;
border: 1px solid transparent;
transition: var(--transition);
text-align: center;
width: 100%;
max-height: 500px;
}

.project__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.project__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 245px;
}

.project__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

project__item h3 {
    margin: 1.2rem 0 2rem;
}

.project__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

/* Media queries (Medium devices) */
@media screen and (max-width: 1100px) {
    .project__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    }

/* Media queries (Small devices) */
@media screen and (max-width: 600px) {
    .project__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}